body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.EstablishmentDetail_EstablishmentDetail__EhPiX {
  list-style-type: none;
  padding: 0px;
  width: 100%;
}
.CityPicker_CityPicker__1Ode1 {
  display: flex;
  flex-direction: column;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}

.CityPicker_CityPicker__1Ode1 > li {
  list-style-type: none;
  font-size: 40px;
  padding: 0 0 25px 0;
  margin: 0;
  flex-direction: row;
  text-align: left;
}

.CityPicker_CityPicker__1Ode1 li > span {
  padding: 0 20px 0 0;
}
